img {
  width: auto;
  height: auto;
}

ul {
  margin: 0;
}

.Toastify__toast {
  min-height: 45px;
}

/*.Toastify__toast-container {*/
/*  width: auto;*/
/*}*/

.Toastify__toast--success {
  border: 1px solid #08bc0c;
}

.Toastify__toast--error {
  border: 1px solid #e74c3c;
}

.Toastify__close-button {
  align-self: center;
}

.date-picker-full-width {
	width: 100%;
}